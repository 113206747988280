import React from "react";
import "../styles/components/home.css";
import monData from "../json/monData.json";

const GalleryPage = ({ openModal }) => {
  return (
    <main>
      <div className="header left-aligned white-text">MAGEICALS</div>

      <div className="one-col container">
        <div>
          <div className="centered-image">
            <img src='../images/loading.gif' alt="" width="400" height="400" />
          </div>

          <div className="centered text">
              <p>
                NEAR's Future Marketplace - Built with $MAGIC
              </p>
            </div>
          </div>
      </div>
    </main>
  );
};

export default GalleryPage;
